











import { Component, Mixins, Watch } from 'vue-property-decorator';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { ListingStatus, ListingCategory, MethodOfSale, PropertyCategory } from 'client-website-ts-library/types';
import { View } from 'client-website-ts-library/plugins';

import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
  },
})
export default class BusinessesForSale extends Mixins(View) {
  private listingFilter: ListingFilter | null = null;

  mounted() {
    this.updateFilter();
  }

  updateFilter() {
    const filter = new ListingFilter({
      Statuses: [
        ListingStatus.Current,
        ListingStatus.UnderContract,
      ],
      Categories: [
        ListingCategory.Business,
      ],
      PageSize: 9,
      OrderByStatements: [
        ListingOrderBy.CreateDateDesc,
      ],
    });

    this.listingFilter = filter;
  }

  handleFilter(filter: ListingFilter) {
    this.listingFilter = filter;
  }
}
